import Excel from "exceljs";
import * as FileSaver from "file-saver";
import moment from 'moment'

export const excelExport = {

  workbook: null,
  worksheet: null,

  createExcel: function() {
    // 创建工作簿
    let workbook = new Excel.Workbook();
    // 设置工作簿的属性
    workbook.creator = "ddshop";
    workbook.lastModifiedBy = "ddshop";
    workbook.created = new Date();
    workbook.modified = new Date();
    let worksheet = workbook.addWorksheet("配送列表");

    worksheet.properties.defaultColWidth = 30;

    this.workbook = workbook;
    this.worksheet = worksheet;
  },

  downloadExcel: function(name) {
    this.workbook.xlsx.writeBuffer().then((data) => {
      const EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const blob = new Blob([data], { type: EXCEL_TYPE });
      FileSaver.saveAs(blob, `${name}.xlsx`); //表单名字
    });
  },

  createRow(row, rowIdx) {
    let exRow = this.worksheet.getRow(rowIdx)

    exRow.alignment = { vertical: "middle", horizontal: "center", wrapText: true }
    for (let i = 1; i < 11; i++) {
      exRow.getCell(i).border = {
        top: {style:'thin'},
        left: {style:'thin'},
        bottom: {style:'thin'},
        right: {style:'thin'}
      };
    } 

    exRow.getCell(1).value = "乾县万通配送中心";
    exRow.getCell(2).value = moment(new Date()).format("YYYY年MM月DD日");
    exRow.getCell(3).value = "质优价廉 售后无忧";

    exRow.getCell(4).value = row.orderAddress
    exRow.getCell(5).value = row.receiverName
    exRow.getCell(6).value = row.memberPhone
    exRow.getCell(7).value = row.productDetailStr
    exRow.getCell(8).value = "总计：" + parseFloat(row.paymentAmount).toFixed(2) + '元'

    exRow.getCell(9).value = "公司地址：乾县万通农贸市场内"
    exRow.getCell(10).value = "180 6434 4818"

    // this.worksheet.getRow(rowIdx).getCell(1).value = str;
  },

  createHead() {
    let exRow = this.worksheet.getRow(1)

    exRow.alignment = { vertical: "middle", horizontal: "center", wrapText: true }
    for (let i = 1; i < 11; i++) {
      exRow.getCell(i).border = {
        top: {style:'thin'},
        left: {style:'thin'},
        bottom: {style:'thin'},
        right: {style:'thin'}
      };
    }

    exRow.getCell(1).value = "公司名称";
    exRow.getCell(2).value = "下单日期";
    exRow.getCell(3).value = "宣传语";

    exRow.getCell(4).value = "客户地址"
    exRow.getCell(5).value = "客户姓名"
    exRow.getCell(6).value = "客户电话"
    exRow.getCell(7).value = "客户菜单/金额"
    exRow.getCell(8).value = "总计"

    exRow.getCell(9).value = "公司地址"
    exRow.getCell(10).value = "售后电话"
  },

  exprtEvent: function(list, excelName) {

    this.createExcel();

    this.createHead();

    list.forEach((row, rowIdx) => {
      this.createRow(row, rowIdx + 2)
    });

    this.downloadExcel(excelName);
  }
};