import http from '@/utils/http'

const delivery = {
  dmList(data) {
    return http.post('/api/deliveryman/list', data)
  },
  dmCreate(data) {
    return http.post('/api/deliveryman/create', data)
  },
  dmUpdate(data) {
    return http.post('/api/deliveryman/update', data)
  },
  dmDelete(data) {
    return http.post('/api/deliveryman/delete', data)
  },
  list(data) {
    return http.post('/api/delivery/list', data)
  },
  create(data) {
    return http.post('/api/delivery/create', data)
  },
  delete(data) {
    return http.post('/api/delivery/delete', data)
  },
  addOrder(data) {
    return http.post('/api/delivery/addorder', data)
  },
  removeOrder(data) {
    return http.post('/api/delivery/removeorder', data)
  },
  export(data) {
    return http.post('/api/delivery/export', data)
  },
  finished(data) {
    return http.post('/api/delivery/finished', data)
  },
  detail(data) {
    return http.post('/api/delivery/detail', data)
  },
  update(data) {
    return http.post('/api/delivery/update', data)
  },
  orderItems(data) {
    return http.post('/api/delivery/orderItems', data)
  },
}

export default delivery