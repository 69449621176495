/**Created by liaoyingchao on 11/24/22.*/

<template>
  <div class="delivery-list page-css list-css" v-loading="pageLoading">
    <div class="top-tools">
      <el-form ref="RefFilterForm" :model="form" label-width="90px">
        <div class="filter-items">
          <el-form-item class="filter-item" label="配送员">
            <el-select v-model="form.dmId" placeholder="请选择配送员" style="width: 100%;" clearable>
              <el-option
                      v-for="item in dmOptions"
                      :key="item.dmId"
                      :label="item.dmName"
                      :value="item.dmId"
              />
            </el-select>
          </el-form-item>
          <el-form-item class="filter-item" label="配送状态">
            <el-select v-model="form.deliveryState" placeholder="请选择配送状态" style="width: 100%;" clearable>
              <el-option
                      v-for="item in stateOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
              />
            </el-select>
          </el-form-item>
          <div class="btns-div">
            <el-button type="primary" :icon="Search" @click="filterEvent">查询</el-button>
            <el-button :icon="Plus" @click="newEvent">新建配送单</el-button>
          </div>
        </div>
      </el-form>
    </div>
    <div class="full-container">
      <TableContainer ref="TabelContainer" :listApiFunction="getList">
        <template #default="tbData">
          <el-table
                  :height="tbData.data.tableHeight"
                  :data="tbData.data.tableData"
                  border
                  stripe
                  header-row-class-name="theader"
                  style="width: 100%">
            <el-table-column
                    prop="dmName"
                    label="配送员姓名"
                    min-width="120">
            </el-table-column>
            <el-table-column
                    prop="dmPhone"
                    label="配送员手机号"
                    min-width="120">
            </el-table-column>
            <el-table-column
                    prop="deliveryTime"
                    label="配送时间"
                    min-width="180">
              <template #default="scope">
                {{ timeYYYY_MM_DD_HH_mm_ss(scope.row.deliveryTime) }}
              </template>
            </el-table-column>
            <el-table-column
                    prop="orderNumber"
                    label="订单数目"
                    min-width="100">
            </el-table-column>
            <el-table-column
                    prop="deliveryDate"
                    label="计划耗时"
                    min-width="100">
            </el-table-column>
            <el-table-column
                    prop="deliveryState"
                    label="配送状态"
                    min-width="120">
              <template #default="scope">
                {{ getDeliveryStateText(scope.row.deliveryState) }}
              </template>
            </el-table-column>
            <el-table-column
                    prop="createdTime"
                    label="创建时间"
                    min-width="180">
              <template #default="scope">
                {{ timeYYYY_MM_DD_HH_mm_ss(scope.row.createdTime) }}
              </template>
            </el-table-column>
            <el-table-column
                    prop="updateTime"
                    label="修改时间"
                    min-width="180">
              <template #default="scope">
                {{ timeYYYY_MM_DD_HH_mm_ss(scope.row.updateTime) }}
              </template>
            </el-table-column>
            <el-table-column
                    fixed="right"
                    label="操作"
                    width="180">
              <template #default="scope">
                <div>
                  <div v-if="scope.row.deliveryState != 40">
                    <el-button type="primary" text @click="addOrder(scope.row)">添加订单</el-button>
                    <el-button type="primary" text @click="editEvent(scope.row)">编辑</el-button>
                  </div>
                  <div>
                    <el-button type="primary" text @click="removeOrder(scope.row)" v-if="scope.row.deliveryState != 40">移除订单</el-button>
                    <el-button type="primary" text @click="exportEvent(scope.row)">导出</el-button>
                  </div>
                  <div v-if="scope.row.deliveryState != 40">
                    <el-button type="warning" text @click="finishedEvent(scope.row)">完成配送</el-button>
                    <el-button type="danger" text @click="deleteEvent(scope.row)">删除</el-button>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </TableContainer>
    </div>
    <el-dialog
            class="center-dialog"
            v-model="dialogVisible"
            :title="editTitle"
            width="80%"
            destroy-on-close
    >
      <div class="dialog-content">
        <el-form ref="Ref_editForm" :model="editForm" :rules="formRules" label-width="120px">
          <el-form-item label="配送员姓名" prop="dmId">
            <el-select v-model="editForm.dmId" placeholder="请选择配送员" style="width: 100%;" clearable>
              <el-option
                      v-for="item in dmOptions"
                      :key="item.dmId"
                      :label="item.dmName"
                      :value="item.dmId"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="配送时间" prop="deliveryTime">
            <el-date-picker
                    style="width: 100%;"
                    v-model="editForm.deliveryTime"
                    type="datetime"
                    placeholder="请选择配送时间"
            />
          </el-form-item>
          <el-form-item label="计划耗时" prop="deliveryDate">
            <el-input v-model="editForm.deliveryDate" placeholder="请输入计划耗时"/>
          </el-form-item>
        </el-form>
        <div style="margin-left: 5%;padding-bottom: 10px;" v-if="editForm.deliveryId == ''">请选择配送订单</div>
        <el-table
                v-if="editForm.deliveryId == ''"
                ref="multipleTableRef"
                :data="tableData"
                style="width: 90%;margin: 0 auto;"
                border
                stripe
                header-row-class-name="theader"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column
                  prop="receiverName"
                  label="收货人姓名"
                  min-width="120">
          </el-table-column>
          <el-table-column
                  prop="memberPhone"
                  label="收货人电话"
                  min-width="120">
          </el-table-column>
          <el-table-column
                  prop="orderAddress"
                  label="收货地址"
                  min-width="200">
          </el-table-column>
          <el-table-column
                  prop="orderAddress"
                  label="收货地址"
                  min-width="200">
          </el-table-column>
          <el-table-column
                  prop="totalAmount"
                  label="支付金额(元)"
                  min-width="110">
          </el-table-column>
          <el-table-column
                  prop="orderRemarks"
                  label="备注"
                  min-width="120">
          </el-table-column>
          <el-table-column
                  prop="orderNumber"
                  label="商品数目"
                  min-width="100">
          </el-table-column>
          <el-table-column
                  prop="orderNumber"
                  label="商品数目"
                  min-width="100">
          </el-table-column>
        </el-table>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="saveEvent">保存</el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog
            class="center-dialog"
            v-model="dOrderDialogVisible"
            :title="dOrderType == '1' ? '添加订单' : '移出订单'"
            width="80%"
            destroy-on-close
    >
      <div class="dialog-content">
        <div class="info-div">
          <div class="info-item">
            <div class="info-label">配送员姓名：</div>
            <div class="info-value">{{ dOrderInfo.dmName }}</div>
          </div>
          <div class="info-item">
            <div class="info-label">配送时间：</div>
            <div class="info-value">{{ timeYYYY_MM_DD_HH_mm_ss(dOrderInfo.deliveryTime) }}</div>
          </div>
          <div class="info-item">
            <div class="info-label">计划耗时：</div>
            <div class="info-value">{{ dOrderInfo.deliveryDate }}</div>
          </div>
        </div>
        <div style="padding-bottom: 10px;">请选择要添加的订单</div>
        <el-table
                ref="multipleAddTableRef"
                :data="tableData"
                style="width: 100%;"
                border
                stripe
                header-row-class-name="theader"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column
                  prop="receiverName"
                  label="收货人姓名"
                  min-width="120">
          </el-table-column>
          <el-table-column
                  prop="memberPhone"
                  label="收货人电话"
                  min-width="120">
          </el-table-column>
          <el-table-column
                  prop="orderAddress"
                  label="收货地址"
                  min-width="200">
          </el-table-column>
          <el-table-column
                  prop="orderAddress"
                  label="收货地址"
                  min-width="200">
          </el-table-column>
          <el-table-column
                  prop="totalAmount"
                  label="支付金额(元)"
                  min-width="110">
          </el-table-column>
          <el-table-column
                  prop="orderRemarks"
                  label="备注"
                  min-width="120">
          </el-table-column>
          <el-table-column
                  prop="orderNumber"
                  label="商品数目"
                  min-width="100">
          </el-table-column>
          <el-table-column
                  prop="orderNumber"
                  label="商品数目"
                  min-width="100">
          </el-table-column>
        </el-table>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dOrderDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="dOrderEvent">{{ dOrderType == '1' ? '添加订单' : '移出订单' }}</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
  import {ref, reactive} from 'vue'
  import {
    Search,
    Plus,
  } from '@element-plus/icons-vue'
  import TableContainer from '@/components/table-container/index'
  import FormCheck from "@/utils/formCheck";
  import orderApi from '@/api/order'
  import deliveryApi from '@/api/delivery'
  import {ElMessage, ElMessageBox} from 'element-plus';
  import {timeYYYY_MM_DD_HH_mm_ss} from '@/utils/time-format.js'
  import { excelExport } from '@/utils/excel-helper.js'

  const tableData = ref([])

  function mgtOrderList() {
    return orderApi.mgtList({
      pageNumber: 1,
      pageSize: 100,
      orderState: 20
    })
  }

  const multipleTableRef = ref(null)

  const pageLoading = ref(false)
  const RefFilterForm = ref(null)

  const dmOptions = ref([])

  function getDmList() {
    deliveryApi.dmList({
      pageNumber: 1,
      pageSize: 100
    }).then(res => {
      if (res.code == 0) {
        dmOptions.value = res.data || [];
      } else {
        ElMessage.error(res.msg);
      }
    })
  }

  getDmList();

  const stateOptions = ref([
    {
    label: '待配送',
    value: 20
  }, {
    label: '配送中',
    value: 30
  }, {
    label: '已完成',
    value: 40
  }])

  function getDeliveryStateText(state) {
    if (!state) {
      return ''
    }
    let statItem = stateOptions.value.find(item => {
      return item.value == state
    }) || {}
    return statItem.label || '';
  }

  const form = reactive({
    dmId: '',
    deliveryState: '',
  })

  function getList(data) {
    return deliveryApi.list(data);
  }

  const TabelContainer = ref(null)

  function filterEvent() {
    TabelContainer.value.filterEvent(form)
  }

  let dialogVisible = ref(false)
  const editTitle = ref('')

  const editForm = reactive({
    deliveryId: '',
    dmId: '',
    deliveryTime: new Date(),
    deliveryDate: '',
  })

  const formRules = {
    dmId: FormCheck.required('请选择配送员'),
    deliveryTime: FormCheck.required('请选择配送时间'),
  }

  const Ref_editForm = ref(null)

  function newEvent() {

    pageLoading.value = true
    mgtOrderList().then(res => {
      if (res.code == 0) {
        tableData.value = res.data || []

        dialogVisible.value = true
        editTitle.value = '新建配送单'

        editForm.dmId = ''
        editForm.deliveryTime = ''
        editForm.deliveryDate = ''
      } else {
        ElMessage.error(res.msg);
      }
    }).finally(() => {
      pageLoading.value = false
    })
  }

  function saveEvent() {
    Ref_editForm.value.validate((valid, fields) => {
      if (valid) {
        console.log('submit!')
        if (editForm.deliveryId == '') {
          let orders = multipleTableRef.value.getSelectionRows();
          pageLoading.value = true
          deliveryApi.create({
            delivery: {
              dmId: editForm.dmId,
              deliveryTime: editForm.deliveryTime,
              deliveryDate: editForm.deliveryDate,
            },
            orders: orders,
          }).then(res => {
            if (res.code == 0) {
              dialogVisible.value = false
              filterEvent();
            } else {
              ElMessage.error(res.msg);
            }
          }).finally(() => {
            pageLoading.value = false
          })
        } else {
          pageLoading.value = true
          deliveryApi.update({
            delivery: {
              deliveryId: editForm.deliveryId,
              dmId: editForm.dmId,
              deliveryTime: editForm.deliveryTime,
              deliveryDate: editForm.deliveryDate,
            },
          }).then(res => {
            if (res.code == 0) {
              dialogVisible.value = false
              // filterEvent();
              TabelContainer.value.loadData()
            } else {
              ElMessage.error(res.msg);
            }
          }).finally(() => {
            pageLoading.value = false
          })
        }
      } else {
        console.log('error submit!', fields)
      }
    })
  }

  function editEvent(item) {
    dialogVisible.value = true
    editTitle.value = '编辑配送单'

    editForm.deliveryId = item.deliveryId
    editForm.dmId = item.dmId
    editForm.deliveryTime = item.deliveryTime
    editForm.deliveryDate = item.deliveryDate
  }

  function deleteEvent(item) {
    ElMessageBox.confirm('您确定需要删除该配送单吗？', '删除提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => {
      pageLoading.value = true
      deliveryApi.delete({
        deliveryId: item.deliveryId
      }).then(res => {
        if (res.code == 0) {
          // filterEvent();
          TabelContainer.value.loadData()
        } else {
          ElMessage.error(res.msg);
        }
      }).finally(() => {
        pageLoading.value = false
      })
    }).catch((e) => {
      console.error(e)
    })
  }

  const multipleAddTableRef = ref(null)
  const dOrderType = ref('1')
  let dOrderDialogVisible = ref(false)
  const dOrderInfo = reactive({
    dmName: '',
    deliveryTime: '',
    deliveryDate: '',
  })

  function addOrder(item) {

    pageLoading.value = true
    mgtOrderList().then(res => {
      if (res.code == 0) {
        tableData.value = res.data || []

        dOrderInfo.deliveryId = item.deliveryId
        dOrderInfo.dmName = item.dmName
        dOrderInfo.deliveryTime = item.deliveryTime
        dOrderInfo.deliveryDate = item.deliveryDate

        dOrderDialogVisible.value = true
        dOrderType.value = '1'
      } else {
        ElMessage.error(res.msg);
      }
    }).finally(() => {
      pageLoading.value = false
    })
  }

  function addEvent() {
    let orders = multipleAddTableRef.value.getSelectionRows();
    if (orders.length) {
      pageLoading.value = true
      deliveryApi.addOrder({
        deliveryId: dOrderInfo.deliveryId,
        orders: orders
      }).then(res => {
        if (res.code == 0) {
          ElMessage.success("添加成功！");
          dOrderDialogVisible.value = false
          // filterEvent();
          TabelContainer.value.loadData()
        } else {
          ElMessage.error(res.msg);
        }
      }).finally(() => {
        pageLoading.value = false
      })
    } else {
      dOrderDialogVisible.value = false
    }
  }

  function removeEvent() {
    let orders = multipleAddTableRef.value.getSelectionRows();
    if (orders.length) {
      pageLoading.value = true
      deliveryApi.removeOrder({
        deliveryId: dOrderInfo.deliveryId,
        orders: orders
      }).then(res => {
        if (res.code == 0) {
          ElMessage.success("移出成功！");
          dOrderDialogVisible.value = false
          // filterEvent();
          TabelContainer.value.loadData()
        } else {
          ElMessage.error(res.msg);
        }
      }).finally(() => {
        pageLoading.value = false
      })
    } else {
      dOrderDialogVisible.value = false
    }
  }

  function removeOrder(item) {
    pageLoading.value = true
    deliveryApi.detail({
      deliveryId: item.deliveryId
    }).then(res => {
      if (res.code == 0) {
        let data = res.data || {}
        let delivery = data.delivery || {}
        let orders = data.orders || []
        tableData.value = orders

        dOrderInfo.deliveryId = delivery.deliveryId
        dOrderInfo.dmName = delivery.dmName
        dOrderInfo.deliveryTime = delivery.deliveryTime
        dOrderInfo.deliveryDate = delivery.deliveryDate

        dOrderDialogVisible.value = true
        dOrderType.value = '2'
      } else {
        ElMessage.error(res.msg);
      }
    }).finally(() => {
      pageLoading.value = false
    })
  }

  function dOrderEvent() {
    if (dOrderType.value == '1') {
      addEvent()
    } else {
      removeEvent()
    }
  }
  
  function exportEvent(item) {
    pageLoading.value = true
    deliveryApi.detail({
      deliveryId: item.deliveryId
    }).then(res => {
      if (res.code == 0) {
        let data = res.data || {}
        let delivery = data.delivery || {}
        let orders = data.orders || []


        deliveryApi.orderItems({
          orders: orders
        }).then(res => {
          if (res.code == 0) {
            let orderItems = res.data || []

            createExportList(orders, orderItems, delivery)
          } else {
            ElMessage.error(res.msg);
          }
        })
      } else {
        ElMessage.error(res.msg);
      }
    }).finally(() => {
      pageLoading.value = false
    })
  }

  function createExportList(orders, orderItems, delivery) {
    orders.forEach(item => {
      let orderId = item.orderId

      let proArr = orderItems.filter(oItem => {
        return oItem.orderId == orderId
      }) || []

      let str = ''
      proArr.forEach(pro => {
        if (str) {
          str += "\n"
        }
        let price = parseFloat(pro.psSale * pro.cartNumber).toFixed(2)
        str += pro.productName + ' x' + pro.cartNumber + pro.productUnit + '/' + price + '元'
      })

      item.productDetailStr = str
    })

    let fileName = "配送单_" + delivery.dmName + timeYYYY_MM_DD_HH_mm_ss(delivery.deliveryTime)

    excelExport.exprtEvent(orders, fileName)
  }

  function finishedEvent(item) {
    ElMessageBox.confirm('您确定需要完成该配送单吗？完成之后相关订单都会变成已完成状态。', '删除提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => {
      pageLoading.value = true
      deliveryApi.finished({
        deliveryId: item.deliveryId
      }).then(res => {
        if (res.code == 0) {
          // filterEvent();
          TabelContainer.value.loadData()
        } else {
          ElMessage.error(res.msg);
        }
      }).finally(() => {
        pageLoading.value = false
      })
    }).catch((e) => {
      console.error(e)
    })
  }
</script>

<style lang="stylus" scoped type="text/stylus">
  .delivery-list {
    >>>.dialog-content {
      max-height 60vh;
      overflow-y auto;
      padding-right 40px;
      .theader {
        td, th {
          background-color: #d6eafd !important;
        }
      }

      .info-div {
        display flex;
        flex-wrap wrap;
        align-items center;
        padding-bottom 20px;
        .info-item {
          width 33%;
          display flex;
          align-items center;
          .info-label {

          }
          .info-value {
            flex 1;
          }
        }
      }
    }
  }
</style>